<template>
  <div class="wp100">
    <div class="section section1 wp100">
      <div class="enjoy">
        <a class="orangeA f30">立即体验</a>
      </div>
    </div>
    <div class="section section2 pd100 wp100">
      <div class="auto">
        <div class="f48">为什么要手机扫码点餐系统?</div>
        <div class="f24" style="margin-top: 40px">
          突出门店知名度、提高客户满意度、提高顾客回头率
        </div>
      </div>
      <div class="wd1132 flex justify-between align-center">
        <div class="wd280">
          <img src="../../../assets/images/product/ScanOrder/Frame.png" />
          <div class="f24 fw700" style="margin-top: 40px; margin-bottom: 20px">
            突出门店知名度
          </div>
          <div class="f18">
            智慧餐厅，打造生活与科技融合，享受技术带来的便利
          </div>
        </div>
        <div class="wd280">
          <img src="../../../assets/images/product/ScanOrder/Frame1.png" />
          <div class="f24 fw700" style="margin-top: 40px; margin-bottom: 20px">
            提高客户满意度
          </div>
          <div class="f18">支持在线下单、结算，提高用户体验，积累好口碑</div>
        </div>
        <div class="wd280">
          <img src="../../../assets/images/product/ScanOrder/Frame2.png" />
          <div class="f24 fw700" style="margin-top: 40px; margin-bottom: 20px">
            提高客户回头率
          </div>
          <div class="f18">顾客在餐厅就餐更加人性化，大大提高顾客的回头率</div>
        </div>
      </div>
    </div>
    <div class="section section3 wp100 pd100">
      <div class="auto" style="margin-bottom: 80px">
        <div class="f48">扫码点单 省时省力</div>
        <div class="f24" style="margin-top: 40px">
          通过微信、支付宝扫描二维码点餐，摆脱服务员手动接单，节省门店资源，提高效率
        </div>
      </div>
      <div class="wd1036 flex justify-between align-center">
        <div>
          <img
            src="../../../assets/images/product/ScanOrder/Mask Group1.png"
            alt=""
            srcset=""
          />
        </div>
        <img
          src="../../../assets/images/product/ScanOrder/Group 48.png"
          alt=""
          srcset=""
        />
        <div>
          <img
            src="../../../assets/images/product/ScanOrder/Mask Group2.png"
            alt=""
            srcset=""
          />
        </div>
        <img
          src="../../../assets/images/product/ScanOrder/Group 48.png"
          alt=""
          srcset=""
        />
        <div>
          <img
            src="../../../assets/images/product/ScanOrder/Mask Group3.png"
            alt=""
            srcset=""
          />
        </div>
      </div>
      <div
        class="wd1036 flex justify-between align-center"
        style="margin-top: 50px"
      >
        <div class="wd196">
          <div class="f24">顾客扫码</div>
          <div class="f24" style="margin-top: 10px">自主点单</div>
        </div>
        <div class="wd196">
          <div class="f24">接单系统</div>
          <div class="f24" style="margin-top: 10px">收到订单</div>
        </div>
        <div class="wd196">
          <div class="f24">打印订单</div>
          <div class="f24" style="margin-top: 10px">开始出餐</div>
        </div>
      </div>
    </div>
    <div class="section section4 flex justify-between align-center">
      <div class="wd1200 flex justify-between align-center">
        <div class="wd600 flex justify-center align-center">
          <div>
            <div class="f48 text-blue">告别传统点备模式</div>
            <div class="f24" style="margin-top: 40px">随时随地点餐不排队</div>
          </div>
        </div>
        <div class="wd600 lfImg flex justify-center align-center"></div>
      </div>
    </div>
    <div class="section section5 wp100 pd100">
        <div class="auto" style="margin-bottom:50px">
            <div class="f48">顾客下单流程</div>
        </div>
        <div class="flex align-center orderProcess">
            <div class="lf flex justify-between align-center">
                <div class="text">
                    <div class="f36 fw700" style="margin-bottom:30px">选购商品</div>
                    <div class="f24" style="margin-bottom:10px">可按规格选购，点餐方式</div>
                    <div class="f24">和美团饿了么无异</div>
                </div>
                <img src="../../../assets/images/product/ScanOrder/Phone2.png" style="margin-right:65px">
            </div>
            <div class="mid" style="margin-right:60px">
                <img src="../../../assets/images/product/ScanOrder/line.png" alt="" srcset="">
            </div>
            <div class="rt">
                <div class="top flex justify-between align-center" style="margin-bottom:200px">
                    <img src="../../../assets/images/product/ScanOrder/Phone.png" alt="" srcset="">
                    <div class="text">
                        <div class="f36 fw700" style="margin-bottom:30px">店内扫码点餐</div>
                        <div class="f24" style="margin-bottom:10px">打开微信或支付宝扫描桌子上的</div>
                        <div class="f24">二维码</div>
                    </div>
                </div>
                <div class="bot flex justify-between align-center">
                    <img src="../../../assets/images/product/ScanOrder/Phone3.png" alt="" srcset="">
                    <div class="text">
                        <div class="f36 fw700" style="margin-bottom:30px">下单付款</div>
                        <div class="f24" style="margin-bottom:10px">可使用微信或支付宝在线支付，</div>
                        <div class="f24">顾客可备注订单</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section section6 pd100">
        <div class="auto" style="margin-bottom:80px">
            <div class="f48" style="margin-bottom:40px">一次开发 多端管理</div>
            <div class="f24">多端管理，独立后台，独立管理运营</div>
        </div>
        <div class="wd1200 flex justify-between align-center">
            <div class="wh620" style="margin-right:80px">
                <img style="width: 620px;" src="../../../assets/images/product/ScanOrder/Group 35.png" alt="" srcset="">
            </div>
            <div class="wh580">
                <div class="f36" style="margin-bottom:30px">后台管理系统</div>
                <div class="f24" style="line-height:30px">查看订单、查看营收、财务报表、配置管理、数据统计等等</div>
            </div>
        </div>
        <div class="wd1200 flex justify-between align-center">
            <div class="wh580" style="padding-left:100px;box-sizing:border-box">
                <div class="f36" style="margin-bottom:30px">在线点单</div>
                <div class="f24" style="line-height:30px">微信、支付宝扫码，在线结算查看订单，呼叫服务员等等</div>
            </div>
            <div class="wh620" style="margin-left:250px">
                <img style="width: 260px;" src="../../../assets/images/product/ScanOrder/Phone1.png" alt="" srcset="">
            </div>
        </div>
    </div>
    <div class="section section7 pt100">
        <div class="auto">
            <div class="f36">扫码体验云蓝扫码点单小程序</div>
        </div>
        <div class="code">
            <img src="../../../assets/images/download/saomaCode.jpg" alt="" srcset="" style="width:200px;height:200px">
        </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.section1 {
  height: 710px;
  background-image: url("../../../assets/images/product/ScanOrder/banner 1.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.enjoy {
  position: absolute;
  top: 497px;
  left: 363px;
}
.orangeA {
  display: block;
  width: 250px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  background-color: #f6be45;
  color: #1f2e80;
  border-radius: 35px;
}
.section2 {
  height: 738px;
  box-sizing: border-box;
}
.auto {
  width: 100%;
  text-align: center;
}
.wd1132 {
  width: 1132px;
  margin: 0 auto;
  margin-top: 80px;
}
.wd280 {
  width: 280px;
  height: 320px;
  text-align: center;
  border-radius: 30px;
  box-shadow: 0 2px 5px #ccc;
  padding: 46px 30px 56px 30px;
  box-sizing: border-box;
}
.wd280 img {
  width: 70px;
  height: 70px;
}
.section3 {
  height: 700px;
  background-color: #f7f9fa;
  box-sizing: border-box;
}
.wd1036 {
  width: 1036px;
  margin: 0 auto;
}
.wd196 {
  width: 196px;
  text-align: center;
}
.section4 {
  height: 600px;
}
.wd1200 {
  width: 1200px;
  margin: 0 auto;
}
.wd600 {
  width: 600px;
  height: 440px;
}
.lfImg {
  width: 600px;
  height: 440px;
  background-image: url("../../../assets/images/product/ScanOrder/order-food.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.section5{
    height: 1100px;
    background-color: #f7f9fa;
    box-sizing: border-box;
}
.orderProcess{
    width: 100%;
    padding: 0 240px 0 300px;
    box-sizing: border-box;
}
.lf .text{
    text-align: right;
    margin-right: 65px;
}
.mid img{
    height: 500px;
}
.rt img{
    margin-right: 60px;
}
.section6{
    height: 1400px;
    background-image: url("../../../assets/images/product/ScanOrder/Mask Group.png");
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    color: #fff;
}
.wh620{
    width: 620px;
}
.section7 {
  width: 100%;
  height: 529px;
  box-sizing: border-box;
  background-image: url("../../../assets/images/product/cusApplet/Group 42.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.code{
    width: 100%;
    text-align: center;
    margin-top: 60px;
}
</style>